import { Picture } from 'designsystem/components/1-atoms/Media';
import { useCentralStore } from 'store';

export interface ImageFeatureProps {
	url: string;
	altText: string;
	focalPoint?: { left: number; top: number };
	aspectRatio?: number;
	sizes?: string;
	isCover?: boolean;
	height?: UI.ImageHeight;
	hideImageOnSmallScreens?: boolean;
}

export const ImageFeature = ({ aspectRatio, isCover = true, hideImageOnSmallScreens, ...props }: ImageFeatureProps) => {
	const { isMobile } = useCentralStore();
	return hideImageOnSmallScreens && isMobile ? null : (
		<Picture {...props} isCover={isCover} aspectRatio={!isCover ? null : aspectRatio ? aspectRatio : 0.4} />
	);
};
