import classNames from 'classnames';
import { Heading } from 'components/1-atoms/Heading';
import { Container, ContainerWidth } from 'components/4-habitats/Container';
import { Grid } from 'components/4-habitats/Grid';
import styles from './ArticleHeader.module.scss';

export interface ArticleHeaderProps {
	className?: string;
	teaser?: string;
	heading?: React.ReactNode;
	pageWidth: ContainerWidth;
}

export const ArticleHeader: React.FC<ArticleHeaderProps> = ({ className, teaser, heading, pageWidth }) => (
	<div className={classNames(styles.ArticleHeader, className)}>
		<Container width={pageWidth}>
			<Grid full wrap>
				<div className={styles.ArticleHeader_heading}>
					<Heading headingLevel="h1" style="xl">
						{heading}
					</Heading>
				</div>
				{teaser ? <div className={styles.ArticleHeader_lead}>{teaser}</div> : null}
			</Grid>
		</Container>
	</div>
);
