import classNames from 'classnames';
import React from 'react';
import styles from './ArticleMedia.module.scss';

export interface ArticleMediaProps {
	caption: string;
	className?: string;
	width?: UI.ImageWidth;
	customWidth?: string;
	image: React.ReactNode;
	margined?: boolean;
	position?: 'Left' | 'Center' | 'Right';
	verticalPosition?: 'Top' | 'Center' | 'Bottom';
}
export const ArticleMedia: React.FC<ArticleMediaProps> = ({
	caption,
	className,
	width = 'Medium',
	customWidth,
	image,
	margined,
	position,
	verticalPosition,
}) => {
	const articlePicture = (
		<span className={styles.ArticleMedia_mediabox}>
			<div className={styles.ArticleMedia_picture}>{image}</div>
		</span>
	);

	return (
		<div
			className={classNames(
				styles.ArticleMedia,
				margined && styles.ArticleMedia___margined,
				!width && !customWidth && styles.ArticleMedia___heightControlled,
				(width || customWidth) && styles.ArticleMedia___widthControlled,
				width && !customWidth && styles[`ArticleMedia___width${width}`],
				position && styles[`ArticleMedia___${position}`],
				verticalPosition && styles[`ArticleMedia___v${verticalPosition}`],
				className,
			)}
			style={customWidth ? { maxWidth: customWidth + 'px' } : undefined}
		>
			{caption ? (
				<figure>
					{image && articlePicture}
					<figcaption className={styles.ArticleMedia_figureCaption}>{caption}</figcaption>
				</figure>
			) : (
				image && articlePicture
			)}
		</div>
	);
};
