import classNames from 'classnames';
import { Heading } from 'components/1-atoms/Heading';
import { ArticleBylineProps } from '../ArticleByline/ArticleByline';
import styles from './ArticleAuthor.module.scss';

export interface ArticleAuthorProps extends ArticleBylineProps {
	bio: string;
	email: string;
}

export const ArticleAuthor: React.FC<ArticleAuthorProps> = ({ className, fullName, workTitle, email, bio }) => (
	<footer className={classNames(styles.ArticleAuthor, className)}>
		<div className={styles.ArticleAuthor_name}>
			<Heading headingLevel="h4" style="md">
				{fullName}
			</Heading>
		</div>
		<div className={styles.ArticleAuthor_title}>{workTitle}</div>
		<div className={styles.ArticleAuthor_email}>
			<a className={styles.ArticleAuthor_link} href={email}>
				{email}
			</a>
		</div>

		<div className={styles.ArticleAuthor_bio}>{bio}</div>
	</footer>
);
